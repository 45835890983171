import React from "react"
import Navbar from "../../components/navbar"
import Container from "../../components/container"
import Header from "../../components/header"
import Layout from "../../components/layout"
import BackgroundShape from "../../components/backgroundShape"
import Head from "../../components/head"
import TableEntryShipping from "../../components/tableEntryShipping"
import { graphql, useStaticQuery } from "gatsby"

export default function ShippingStatus () {
    const data = useStaticQuery(graphql`
        query {
            allContentfulShippingIntegrations{
                edges {
                    node {
                        title
                        labels
                        trackingSync
                        signature
                        pickup
                        insurance
                        multiParcelShipping
                        international
                    }
                }    
            }
        }
    `)


    return (
        <Layout navbar="transparent">
        <Head title="Shipping Integrations" description="From basic dropshipping & automation to Advanced and custom reporting, we’ve got you covered. Find the right package for your business"/>
         
        <BackgroundShape style={{shape: "clip-fixed-small", color: "gradient", particles: true}}/>
        <Container style={{classes: "container" }}>
          <div>
            <Header content={{title: "Shipping Integrations", text: "Check the current capabilities of our integrations"}} style={{color: "text-center"}}/>
          </div>
        </Container>
        <Container style={{classes: "container mt-5 pt-5" }}>
        <div className='shipping-table-entry' data-sal='slide-up' data-sal-easing='ease' data-sal-delay='100' data-sal-duration='800'>
            <p className="table-header"></p>
            <p className="table-header">Labels</p>
            <p className="table-header">Tracking<br/>Sync</p>
            <p className="table-header">Signature</p>
            <p className="table-header">Pickup</p>
            <p className="table-header">Insurance</p>
            <p className="table-header">Multi Parcel<br/>Shipping</p>
            <p className="table-header">International</p>
        </div>
        {data.allContentfulShippingIntegrations.edges.map((edge) => {
            return(
                <TableEntryShipping
                    data={{
                        title: edge.node.title,
                        labels: edge.node.labels,
                        trackingSync: edge.node.trackingSync,
                        signature: edge.node.signature,
                        pickup: edge.node.pickup,
                        insurance: edge.node.insurance,
                        multiParcelShipping: edge.node.multiParcelShipping,
                        international: edge.node.international,
                }}/>
            )})
        }
        </Container>
      </Layout>
    )
}
